<template>
  <div class="path">
    <vue-custom-scrollbar class="scroll-area"  :settings="settings" >
    <div class="workPlace" v-if="onewayShow==null && oneseatShow==null && twoseatShow==null && threeseatShow==null">
      <div v-if="oneway!=null">
        <div class="blockplace" v-for="(group,index) in oneway" :key="group.key" @click="showOneway(index)">
          <span class="title">
            {{group.path.length}} {{chisl(group.path.length,['остановка','остановки','остановок'])}}
          </span>
          <span class="blocktype">без пересадок</span>
          <div class="waysplace">
            <div :class="['way',((ind5+1)%5 == 0)?'last':'']" v-for="(way,ind5) in group.way" :key="ind5" :style="{background:group.color[ind5]}">
              {{way}}
            </div>
          </div>
        </div>
      </div>
      <div v-if="oneseat!=null">
        <div class="blockplace" v-for="(group,index) in oneseat" :key="group.key" @click="showOneSeat(index)">

          <span class="title">
            {{group.first.path.length+group.second.path.length-1}} {{chisl((group.first.path.length+group.second.path.length-1),['остановка','остановки','остановок'])}}
          </span>
          <span class="blocktype">одна пересадка</span>

          <div class="waysplace">
            <div :class="['way',((ind3+1)%5 == 0)?'last':'']" v-for="(way,ind3) in group.first.way" :key="ind3+(Math.random(1000))" :style="{background:group.first.color[ind3]}">
              {{way}}
            </div>
            <div class="triangle-right"></div>
            <div :class="['way',((ind4+1)%5 == 0)?'last':'']" v-for="(way,ind4) in group.second.way" :key="ind4+(Math.random(1000))" :style="{background:group.second.color[ind4]}">
              {{way}}
            </div>
          </div>
        </div>
      </div>

      <div v-if="twoseat!=null">
        <div class="blockplace" v-for="(group,index) in twoseat" :key="group.key" @click="showTwoSeat(index)">

          <span class="title">
            {{group.first.path.length+group.second.path.length+group.third.path.length-2}} {{chisl((group.first.path.length+group.second.path.length+group.third.path.length-2),['остановка','остановки','остановок'])}}
          </span>
          <span class="blocktype">две пересадки</span>
          <div class="waysplace">

            <div :class="['way',((ind+1)%5 == 0)?'last':'']" v-for="(way,ind) in group.first.way" :key="ind+(Math.random(1000))" :style="{background:group.first.color[ind]}">
              {{way}}
            </div>
            <div class="triangle-right"></div>
            <div :class="['way',((ind1+1)%5 == 0)?'last':'']" v-for="(way,ind1) in group.second.way" :key="ind1+(Math.random(1000))" :style="{background:group.second.color[ind1]}">
              {{way}}
            </div>
            <div class="triangle-right"></div>
            <div :class="['way',((ind2+1)%5 == 0)?'last':'']" v-for="(way,ind2) in group.third.way" :key="ind2+(Math.random(1000))" :style="{background:group.third.color[ind2]}">
              {{way}}
            </div>
          </div>
        </div>
      </div>

    </div>
    <Detail
        v-if="onewayShow!=null || oneseatShow!=null || twoseatShow!=null || threeseatShow!=null"
        v-bind:oneway="onewayShow"
        v-bind:clicked="clicked"
        v-bind:oneseat="oneseatShow"
        v-bind:twoseat="twoseatShow"
        v-bind:threeseat="threeseatShow"
        v-on:CloseDetail="clearShow()"
    />
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import Detail from "@/components/Detail";
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
export default {
  name: 'Paths',
  props: ['data','clicked'],
  components: { Detail,vueCustomScrollbar },
  mounted() {
    this.initData()
  },
  data() {
    return {
      oneway:null,
      oneseat: null,
      twoseat: null,
      threeseat:null,

      onewayShow:null,
      oneseatShow: null,
      twoseatShow: null,
      threeseatShow:null,


      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false
      }
    }
  },
  methods: {
    initData() {
      let _this = this
      let postr = false
      this.data.forEach(function(item) {
        switch (item.result) {
          case "oneway":
            _this.oneway = item.data
              if (!postr) {
                _this.$emit('drawPath',{path:[item.data[0].path],route:[item.data[0].route],color:[item.data[0].color[0]]})
                postr=true
              }
            break
          case "oneseat":
            _this.oneseat = item.data
            if (!postr) {
              _this.$emit('drawPath',{path:[item.data[0].first.path,item.data[0].second.path],route:[item.data[0].first.route,item.data[0].second.route],color:[item.data[0].first.color[0],item.data[0].second.color[0]]})
              postr=true
            }
            break
          case "twoseat":
            _this.twoseat = item.data
            if (!postr) {
              _this.$emit('drawPath',{path:[item.data[0].first.path,item.data[0].second.path,item.data[0].third.path],route:[item.data[0].first.route,item.data[0].second.route,item.data[0].third.route],color:[item.data[0].first.color[0],item.data[0].second.color[0],item.data[0].third.color[0]]})
              postr=true
            }
            break
          case "threeseat":
            _this.threeseat = item.data
            break
          default:
            console.warn(item)
        }
      })
    },
    chisl(number, titles) {
      var cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
    showOneway(index) {
      this.onewayShow = this.oneway[index]
      this.$emit('drawPath',{path:[this.oneway[index].path],route:[this.oneway[index].route],color:[this.oneway[index].color[0]]})
    },
    showOneSeat(index) {
      this.oneseatShow = this.oneseat[index]
      this.$emit('drawPath',{path:[this.oneseat[index].first.path,this.oneseat[index].second.path],route:[this.oneseat[index].first.route,this.oneseat[index].second.route],color:[this.oneseat[index].first.color[0],this.oneseat[index].second.color[0]]})
    },
    showTwoSeat(index) {
      this.twoseatShow = this.twoseat[index]
      this.$emit('drawPath',{path:[this.twoseat[index].first.path,this.twoseat[index].second.path,this.twoseat[index].third.path],route:[this.twoseat[index].first.route,this.twoseat[index].second.route,this.twoseat[index].third.route],color:[this.twoseat[index].first.color[0],this.twoseat[index].second.color[0],this.twoseat[index].third.color[0]]})
    },
    clearShow() {
      this.onewayShow=null
      this.oneseatShow= null
      this.twoseatShow= null
      this.threeseatShow=null
    }
  }
}
</script>

<style scoped>
.path {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
  left: 0;
  right: 0;
  background: #FAFAFA;
}
.workPlace {
  padding: 20px 41px 30px 59px;
  position: relative;
  background: #FAFAFA;
  flex-grow: 1;
}
.blockplace {
  background: #F2F4F5;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  display: block;
}
.blockplace .blocktype {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #9CACBA;
}
.waysplace {
  margin-top: 25px;
  display: flex;
  align-items: center;
}
.way {
  display: inline-block;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  text-align: center;
  padding: 8px 0;
  width: 40px;
  position: relative;
  margin-bottom: 5px;
  margin-right: 3px;
}
.way.last {

}
.triangle-right {
  display: inline-block;
  position: relative;
  margin: 0 3px;
  width: 10px;
  height: 20px;
  margin-bottom: 5px;
}
.triangle-right:before {
  content: "";
  position: absolute;
  top: calc(50% - 7px);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #B4B4B4;
  display: inline-block;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
