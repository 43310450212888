import Vue from 'vue'
import App from './App.vue'

import Conf from '@/vars/conf'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  data: Conf,
  el: '#app',
})
