<template>
  <div class="way" >
    <div class="devider"></div>
    <vue-custom-scrollbar class="scroll-area"  :settings="settings" >
    <div class="info">
      <div class="toBack" @click="$emit('CloseDetail',null)"></div>
      <div class="title" @click.right="kost=!kost">
        {{way.newdata.name}}.<br><span v-html="way.title"></span>
      </div>
      <div class="infomass" v-html="infoRoute" >
      </div>

      <div class="cRoute">
        <div :class="['changeRoute',routeF?'active':'']"  @click="changeDirect('f')">
          <div v-if="way.newdata.routeF.name!=null" v-html="way.newdata.routeF.name"></div>
          <div v-else><span>{{ way.newdata.routeF.from }}</span> — <span>{{ way.newdata.routeF.to }}</span></div>
        </div>
        <div :class="['changeRoute',routeB?'active':'']"  @click="changeDirect('b')" v-if="way.newdata.routeB.length!=0">
          <div v-if="way.newdata.routeB.name!=null" v-html="way.newdata.routeB.name"></div>
          <div v-else><span>{{ way.newdata.routeB.from }}</span> — <span>{{ way.newdata.routeB.to }}</span></div>
        </div>
        <div :class="['changeRoute',routeA?'active':'']"  @click="changeDirect('a')" v-if="way.newdata.routeA.length!=0">
          <div v-if="way.newdata.routeA.name!=null" v-html="way.newdata.routeA.name"></div>
          <div v-else><span>{{ way.newdata.routeA.from }}</span> — <span>{{ way.newdata.routeA.to }}</span></div>
        </div>
        <div :class="['changeRoute',routeAB?'active':'']"  @click="changeDirect('ab')" v-if="way.newdata.routeAB.length!=0">
          <div v-if="way.newdata.routeAB.name!=null" v-html="way.newdata.routeAB.name"></div>
          <div v-else><span>{{ way.newdata.routeAB.from }}</span> — <span>{{ way.newdata.routeAB.to }}</span></div>
        </div>

        <div :class="['changeRoute',route5?'active':'']"  @click="changeDirect('r5')" v-if="way.newdata.route5.length!=0">
          <div v-if="way.newdata.route5.name!=null" v-html="way.newdata.route5.name"></div>
          <div v-else><span>{{ way.newdata.route5.from }}</span> — <span>{{ way.newdata.route5.to }}</span></div>
        </div>
        <div :class="['changeRoute',route6?'active':'']"  @click="changeDirect('r6')" v-if="way.newdata.route6.length!=0">
          <div v-if="way.newdata.route6.name!=null" v-html="way.newdata.route6.name"></div>
          <div v-else><span>{{ way.newdata.route6.from }}</span> — <span>{{ way.newdata.route6.to }}</span></div>
        </div>
        <div :class="['changeRoute',route7?'active':'']"  @click="changeDirect('r7')" v-if="way.newdata.route7.length!=0">
          <div v-if="way.newdata.route7.name!=null" v-html="way.newdata.route7.name"></div>
          <div v-else><span>{{ way.newdata.route7.from }}</span> — <span>{{ way.newdata.route7.to }}</span></div>
        </div>
        <div :class="['changeRoute',route8?'active':'']"  @click="changeDirect('r8')" v-if="way.newdata.route8.length!=0">
          <div v-if="way.newdata.route8.name!=null" v-html="way.newdata.route8.name"></div>
          <div v-else><span>{{ way.newdata.route8.from }}</span> — <span>{{ way.newdata.route8.to }}</span></div>
        </div>
      </div>
    </div>


    <div v-if="routeF" class="routePlace">
      <div class="waysplace">

        <div class="pathPlace">
          <div class="leftLine" :style="{backgroundColor: way.background}"></div>
          <div :class="['pathStop']" >
            <div
                :class="['stop',(index==0)?'start':'',(index+1==way.newdata.routeF.stops.length)?'end':'',(way.newdata.routeF.stops.length>hideMin && index>hideStart && index<(way.newdata.routeF.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                v-for="(wayM,index) in way.newdata.routeF.stops"
                :key="wayM.id+getRandomInt(1000000)" >
              <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.routeF.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

              <span v-if="index!=hideStart || !hideMore">{{wayM.name}}</span>

              <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.routeF.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.routeF.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
              <!--// расписание//-->
              <div v-if="index==0 && timesFrom!=null" >
                <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                  <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesFromDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                    {{days[0]}}
                  </div>
                </div>


                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <transition-group name="fade-up" target="div" appear >

                  <div :class="['timesSpace']" v-for="(rasp, indr) in timesFromDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                    <div class="timeLegend">
                      <div>ч</div>
                      <div>мин</div>
                    </div>
                    <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                      <span>{{hours[0]}}</span>
                      <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                        {{minutes}}
                      </div>
                    </div>
                  </div>
                </transition-group>

              </div>




            </div>

          </div>
        </div>
      </div>
    </div>

    <div v-if="routeB" class="routePlace">
      <div class="waysplace">

        <div class="pathPlace">
          <div class="leftLine" :style="{backgroundColor: way.background}"></div>
          <div :class="['pathStop']" >
            <div
                :class="['stop',(index==0)?'start':'',(index+1==way.newdata.routeB.stops.length)?'end':'',(way.newdata.routeB.stops.length>hideMin && index>hideStart && index<(way.newdata.routeB.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                v-for="(wayM,index) in way.newdata.routeB.stops"
                :key="wayM.id+getRandomInt(1000000)">
              <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.routeB.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

              <span v-if="index!=hideStart || !hideMore" >{{wayM.name}}</span>

              <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.routeB.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.routeB.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
              <!--// //-->

              <div v-if="index==0 && timesTo!=null" >
                <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                  <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesToDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                    {{days[0]}}
                  </div>
                </div>
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <transition-group name="fade-up" target="div" appear >
                  <div :class="['timesSpace']" v-for="(rasp, indr) in timesToDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                    <div class="timeLegend">
                      <div>ч</div>
                      <div>мин</div>
                    </div>
                    <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                      <span>{{hours[0]}}</span>
                      <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                        {{minutes}}
                      </div>
                    </div>
                  </div>
                </transition-group>

              </div>


            </div>

          </div>
        </div>
      </div>
    </div>

      <div v-if="routeA" class="routePlace">
        <div class="waysplace">

          <div class="pathPlace">
            <div class="leftLine" :style="{backgroundColor: way.background}"></div>
            <div :class="['pathStop']" >
              <div
                  :class="['stop',(index==0)?'start':'',(index+1==way.newdata.routeA.stops.length)?'end':'',(way.newdata.routeA.stops.length>hideMin && index>hideStart && index<(way.newdata.routeA.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                  v-for="(wayM,index) in way.newdata.routeA.stops"
                  :key="wayM.id+getRandomInt(1000000)" >
                <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.routeA.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

                <span v-if="index!=hideStart || !hideMore">{{wayM.name}}</span>

                <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.routeA.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.routeA.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
                <!--// расписание//-->
                <div v-if="index==0 && timesAlt!=null" >
                  <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                    <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesAltDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                      {{days[0]}}
                    </div>
                  </div>
                  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                  <transition-group name="fade-up" target="div" appear >
                    <div :class="['timesSpace']" v-for="(rasp, indr) in timesAltDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                      <div class="timeLegend">
                        <div>ч</div>
                        <div>мин</div>
                      </div>
                      <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                        <span>{{hours[0]}}</span>
                        <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                          {{minutes}}
                        </div>
                      </div>
                    </div>
                  </transition-group>

                </div>




              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="routeAB" class="routePlace">
        <div class="waysplace">

          <div class="pathPlace">
            <div class="leftLine" :style="{backgroundColor: way.background}"></div>
            <div :class="['pathStop']" >
              <div
                  :class="['stop',(index==0)?'start':'',(index+1==way.newdata.routeAB.stops.length)?'end':'',(way.newdata.routeAB.stops.length>hideMin && index>hideStart && index<(way.newdata.routeAB.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                  v-for="(wayM,index) in way.newdata.routeAB.stops"
                  :key="wayM.id+getRandomInt(1000000)" >
                <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.routeAB.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

                <span v-if="index!=hideStart || !hideMore">{{wayM.name}}</span>

                <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.routeAB.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.routeAB.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
                <!--// расписание//-->
                <div v-if="index==0 && timesAltB!=null" >
                  <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                    <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesAltBDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                      {{days[0]}}
                    </div>
                  </div>
                  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                  <transition-group name="fade-up" target="div" appear >
                    <div :class="['timesSpace']" v-for="(rasp, indr) in timesAltBDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                      <div class="timeLegend">
                        <div>ч</div>
                        <div>мин</div>
                      </div>
                      <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                        <span>{{hours[0]}}</span>
                        <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                          {{minutes}}
                        </div>
                      </div>
                    </div>
                  </transition-group>

                </div>




              </div>

            </div>
          </div>
        </div>
      </div>

      <div v-if="route5" class="routePlace">
        <div class="waysplace">

          <div class="pathPlace">
            <div class="leftLine" :style="{backgroundColor: way.background}"></div>
            <div :class="['pathStop']" >
              <div
                  :class="['stop',(index==0)?'start':'',(index+1==way.newdata.route5.stops.length)?'end':'',(way.newdata.route5.stops.length>hideMin && index>hideStart && index<(way.newdata.route5.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                  v-for="(wayM,index) in way.newdata.route5.stops"
                  :key="wayM.id+getRandomInt(1000000)" >
                <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.route5.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

                <span v-if="index!=hideStart || !hideMore">{{wayM.name}}</span>

                <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.route5.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.route5.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
                <!--// расписание//-->
                <div v-if="index==0 && times5!=null" >
                  <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                    <div :class="{'is-active': show == ind}" v-for="(days, ind) in times5Days" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                      {{days[0]}}
                    </div>
                  </div>
                  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                  <transition-group name="fade-up" target="div" appear >
                    <div :class="['timesSpace']" v-for="(rasp, indr) in times5Days" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                      <div class="timeLegend">
                        <div>ч</div>
                        <div>мин</div>
                      </div>
                      <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                        <span>{{hours[0]}}</span>
                        <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                          {{minutes}}
                        </div>
                      </div>
                    </div>
                  </transition-group>

                </div>




              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="route6" class="routePlace">
        <div class="waysplace">

          <div class="pathPlace">
            <div class="leftLine" :style="{backgroundColor: way.background}"></div>
            <div :class="['pathStop']" >
              <div
                  :class="['stop',(index==0)?'start':'',(index+1==way.newdata.route6.stops.length)?'end':'',(way.newdata.route6.stops.length>hideMin && index>hideStart && index<(way.newdata.route6.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                  v-for="(wayM,index) in way.newdata.route6.stops"
                  :key="wayM.id+getRandomInt(1000000)" >
                <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.route6.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

                <span v-if="index!=hideStart || !hideMore">{{wayM.name}}</span>

                <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.route6.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.route6.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
                <!--// расписание//-->
                <div v-if="index==0 && times6!=null" >
                  <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                    <div :class="{'is-active': show == ind}" v-for="(days, ind) in times6Days" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                      {{days[0]}}
                    </div>
                  </div>
                  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                  <transition-group name="fade-up" target="div" appear >
                    <div :class="['timesSpace']" v-for="(rasp, indr) in times6Days" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                      <div class="timeLegend">
                        <div>ч</div>
                        <div>мин</div>
                      </div>
                      <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                        <span>{{hours[0]}}</span>
                        <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                          {{minutes}}
                        </div>
                      </div>
                    </div>
                  </transition-group>

                </div>




              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="route7" class="routePlace">
        <div class="waysplace">

          <div class="pathPlace">
            <div class="leftLine" :style="{backgroundColor: way.background}"></div>
            <div :class="['pathStop']" >
              <div
                  :class="['stop',(index==0)?'start':'',(index+1==way.newdata.route7.stops.length)?'end':'',(way.newdata.route7.stops.length>hideMin && index>hideStart && index<(way.newdata.route7.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                  v-for="(wayM,index) in way.newdata.route7.stops"
                  :key="wayM.id+getRandomInt(1000000)" >
                <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.route7.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

                <span v-if="index!=hideStart || !hideMore">{{wayM.name}}</span>

                <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.route7.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.route7.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
                <!--// расписание//-->
                <div v-if="index==0 && times7!=null" >
                  <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                    <div :class="{'is-active': show == ind}" v-for="(days, ind) in times7Days" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                      {{days[0]}}
                    </div>
                  </div>
                  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                  <transition-group name="fade-up" target="div" appear >
                    <div :class="['timesSpace']" v-for="(rasp, indr) in times7Days" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                      <div class="timeLegend">
                        <div>ч</div>
                        <div>мин</div>
                      </div>
                      <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                        <span>{{hours[0]}}</span>
                        <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                          {{minutes}}
                        </div>
                      </div>
                    </div>
                  </transition-group>

                </div>




              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="route8" class="routePlace">
        <div class="waysplace">

          <div class="pathPlace">
            <div class="leftLine" :style="{backgroundColor: way.background}"></div>
            <div :class="['pathStop']" >
              <div
                  :class="['stop',(index==0)?'start':'',(index+1==way.newdata.route8.stops.length)?'end':'',(way.newdata.route8.stops.length>hideMin && index>hideStart && index<(way.newdata.route8.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                  v-for="(wayM,index) in way.newdata.route8.stops"
                  :key="wayM.id+getRandomInt(1000000)" >
                <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.route8.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

                <span v-if="index!=hideStart || !hideMore">{{wayM.name}}</span>

                <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.route8.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.route8.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
                <!--// расписание//-->
                <div v-if="index==0 && times8!=null" >
                  <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                    <div :class="{'is-active': show == ind}" v-for="(days, ind) in times8Days" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                      {{days[0]}}
                    </div>
                  </div>
                  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                  <transition-group name="fade-up" target="div" appear >
                    <div :class="['timesSpace']" v-for="(rasp, indr) in times8Days" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                      <div class="timeLegend">
                        <div>ч</div>
                        <div>мин</div>
                      </div>
                      <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                        <span>{{hours[0]}}</span>
                        <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                          {{minutes}}
                        </div>
                      </div>
                    </div>
                  </transition-group>

                </div>




              </div>

            </div>
          </div>
        </div>
      </div>
    </vue-custom-scrollbar>

    <div class="kostyil" v-if="kost" >
      <div class="pls" @click.right="kost=false">
        <div v-if="timesFrom!=null" >
          <div class="timesPlace" :class="[(show==-1)?'compact':'']">
            <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesFromDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
              {{days[0]}}
            </div>
          </div>
          <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
          <transition-group name="fade-up" target="div" appear >
            <table :class="['timesSpace']" v-for="(rasp, indr) in timesFromDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
              <div class="timeLegend">
                <div>ч</div>
                <div>мин</div>
              </div>
              <tr v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                <td>{{hours[0]}}</td>
                <td v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                  {{minutes}}
                </td>
              </tr>
            </table>
          </transition-group>

        </div>
        <div v-if="timesTo!=null" >
          <div class="timesPlace" :class="[(show==-1)?'compact':'']">
            <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesToDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
              {{days[0]}}
            </div>
          </div>
          <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
          <transition-group name="fade-up" target="div" appear >
            <table :class="['timesSpace']" v-for="(rasp, indr) in timesToDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
              <div class="timeLegend">
                <div>ч</div>
                <div>мин</div>
              </div>
              <tr v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                <td>{{hours[0]}}</td>
                <td v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                  {{minutes}}
                </td>
              </tr>
            </table>
          </transition-group>

        </div>
        <div v-if="timesAlt!=null" >
          <div class="timesPlace" :class="[(show==-1)?'compact':'']">
            <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesAltDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
              {{days[0]}}
            </div>
          </div>
          <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
          <transition-group name="fade-up" target="div" appear >
            <div :class="['timesSpace']" v-for="(rasp, indr) in timesAltDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
              <div class="timeLegend">
                <div>ч</div>
                <div>мин</div>
              </div>
              <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                <span>{{hours[0]}}</span>
                <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                  {{minutes}}
                </div>
              </div>
            </div>
          </transition-group>

        </div>
      </div>

    </div>
  </div>


</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
export default {
  name: 'Paths',
  props: ['way','clicked'],
  components: { vueCustomScrollbar },
  mounted() {
    this.initData()
    this.parseTimes()
  },
  data() {
    return {
      kost: false,
      routeF:false,
      routeB:false,
      routeA:false,
      routeAB:false,

      route5:false,
      route6:false,
      route7:false,
      route8:false,

      hideMore:true,
      hideMin: 7,
      hideStart: 3,
      hideEnd: 3,
      infoRoute: null,
      timesFrom: null,
      timesFromDays: null,
      timesToDays: null,
      timesTo: null,
      timesAlt: null,
      timesAltB: null,
      timesAltDays: null,
      timesAltBDays: null,

      times5: null,
      times5Days: null,
      times6: null,
      times6Days: null,
      times7: null,
      times7Days: null,
      times8: null,
      times8Days: null,

      showtimes: false,
      show: -1,
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false
      }
    }
  },
  watch: {

    clicked: {
      handler(newValue, oldValue) {
        if(newValue !== oldValue) {
          let _this=this
          this.hideMore = false
          setTimeout(function () {
            var el = _this.$el.getElementsByClassName("clicked")[0];
            el.scrollIntoView({block: 'center'});
          },50)


        }
      },
      deep: true //если нужно отслеживать изменение свойств
    }
  },
  methods: {
    initData() {
      this.routeF=true
    },
    chisl(number, titles) {
      var cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
    changeDirect (dir) {
      switch (dir) {
        case 'f':
          if (this.route5 || this.routeB || this.routeA || this.routeAB || this.route8 || this.route7 || this.route6) {
            this.$emit('drawPath',{path:[this.way.newdata.routeF.stops],route:[this.way.newdata.routeF.route],color:[this.way.background]})
            this.routeB=false
            this.routeF=true
            this.routeA=false
            this.routeAB=false
            this.hideMore=true
          }
          break
        case 'b':
          if (this.routeF || this.route5 || this.routeA || this.routeAB || this.route8 || this.route7 || this.route6) {
            this.$emit('drawPath',{path:[this.way.newdata.routeB.stops],route:[this.way.newdata.routeB.route],color:[this.way.background]})
            this.routeF=false
            this.routeB=true
            this.routeA=false
            this.routeAB=false

            this.route5=false
            this.route6=false
            this.route7=false
            this.route8=false

            this.hideMore=true
          }
          break
        case 'a':
          if (this.routeF || this.routeB || this.route5 || this.routeAB || this.route8 || this.route7 || this.route6) {
            this.$emit('drawPath',{path:[this.way.newdata.routeA.stops],route:[this.way.newdata.routeA.route],color:[this.way.background]})
            this.routeF=false
            this.routeB=false
            this.routeA=true
            this.routeAB=false

            this.route5=false
            this.route6=false
            this.route7=false
            this.route8=false

            this.hideMore=true
          }
          break
        case 'ab':
          if (this.routeF || this.routeB || this.routeA || this.route5 || this.route8 || this.route7 || this.route6) {
            this.$emit('drawPath',{path:[this.way.newdata.routeAB.stops],route:[this.way.newdata.routeAB.route],color:[this.way.background]})
            this.routeF=false
            this.routeB=false
            this.routeA=false
            this.routeAB=true

            this.route5=false
            this.route6=false
            this.route7=false
            this.route8=false

            this.hideMore=true
          }
          break
        case 'r5':
          if (this.routeF || this.routeB || this.routeA || this.routeAB || this.route8 || this.route7 || this.route6) {
            this.$emit('drawPath',{path:[this.way.newdata.route5.stops],route:[this.way.newdata.route5.route],color:[this.way.background]})
            this.routeF=false
            this.routeB=false
            this.routeA=false
            this.routeAB=false

            this.route5=true
            this.route6=false
            this.route7=false
            this.route8=false

            this.hideMore=true
          }
          break
        case 'r6':
          if (this.routeF || this.routeB || this.routeA || this.routeAB || this.route8 || this.route7 || this.route5) {
            this.$emit('drawPath',{path:[this.way.newdata.route6.stops],route:[this.way.newdata.route6.route],color:[this.way.background]})
            this.routeF=false
            this.routeB=false
            this.routeA=false
            this.routeAB=false

            this.route5=false
            this.route6=true
            this.route7=false
            this.route8=false

            this.hideMore=true
          }
          break
        case 'r7':
          if (this.routeF || this.routeB || this.routeA || this.routeAB || this.route8 || this.route5 || this.route6) {

            this.$emit('drawPath',{path:[this.way.newdata.route7.stops],route:[this.way.newdata.route7.route],color:[this.way.background]})

            this.routeF=false
            this.routeB=false
            this.routeA=false
            this.routeAB=false

            this.route5=false
            this.route6=false
            this.route7=true
            this.route8=false

            this.hideMore=true
          }
          break
        case 'r8':
          if (this.routeF || this.routeB || this.routeA || this.routeAB || this.route5 || this.route7 || this.route6) {
            this.$emit('drawPath',{path:[this.way.newdata.route8.stops],route:[this.way.newdata.route8.route],color:[this.way.background]})
            this.routeF=false
            this.routeB=false
            this.routeA=false
            this.routeAB=false

            this.route5=false
            this.route6=false
            this.route7=false
            this.route8=true
            this.hideMore=true
          }
          break
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    parseTimes() {
      var tmsTo = null
      var tmsFrom = null
      var tmsAlt = null
      var tmsAltB = null

      var tms5 = null
      var tms6 = null
      var tms7 = null
      var tms8 = null

      this.infoRoute = this.way.newdata.info;
      var fT = this.infoRoute.split('@');
      if (fT.length==3) {
        this.infoRoute = fT[0] + fT[2];
        tmsFrom = fT[1];
      }
      var tT = this.infoRoute.split('&');
      if (tT.length==3) {
        this.infoRoute = tT[0] + tT[2];
        tmsTo = tT[1];
      }
      var aT = this.infoRoute.split('^');
      if (aT.length==3) {
        this.infoRoute = aT[0] + aT[2];
        tmsAlt = aT[1];
      }
      var abT = this.infoRoute.split('*');
      if (abT.length==3) {
        this.infoRoute = abT[0] + abT[2];
        tmsAltB = abT[1];
      }
      var T5 = this.infoRoute.split('%');
      if (T5.length==3) {
        this.infoRoute = T5[0] + T5[2];
        tms5 = T5[1];
      }
      var T6 = this.infoRoute.split('?');
      if (T6.length==3) {
        this.infoRoute = T6[0] + T6[2];
        tms6 = T6[1];
      }
      var T7 = this.infoRoute.split('!');
      if (T7.length==3) {
        this.infoRoute = T7[0] + T7[2];
        tms7 = T7[1];
      }
      var T8 = this.infoRoute.split('+');
      if (T8.length==3) {
        this.infoRoute = T8[0] + T8[2];
        tms8 = T8[1];
      }
      //распарсим расписание
      //распарсим с началки
      if (tmsFrom!=null) {
        var resF = this.parseByDays(tmsFrom);
        this.timesFrom = resF[0];
        this.timesFromDays = resF[1];
      }
      // распарсим с конечки
      if (tmsTo!=null) {

        var resT = this.parseByDays(tmsTo);
        this.timesTo = resT[0];
        this.timesToDays = resT[1];

      }
      // распарсим альтернативный
      if (tmsAlt!=null) {
        var resA = this.parseByDays(tmsAlt);
        this.timesAltDays = resA[1];
        this.timesAlt = resA[0];
      }
      // распарсим альтернативный обратный
      if (tmsAltB!=null) {
        var resAB = this.parseByDays(tmsAltB);
        this.timesAltBDays = resAB[1];
        this.timesAltB = resAB[0];
      }

      // распарсим 5
      if (tms5!=null) {
        var res5 = this.parseByDays(tms5);
        this.times5Days = res5[1];
        this.times5 = res5[0];
      }
      // распарсим 6
      if (tms6!=null) {
        var res6 = this.parseByDays(tms6);
        this.times6Days = res6[1];
        this.times6 = res6[0];
      }
      // распарсим 7
      if (tms7!=null) {
        var res7 = this.parseByDays(tms7);
        this.times7Days = res7[1];
        this.times7 = res7[0];
      }
      // распарсим 8
      if (tms8!=null) {
        var res8 = this.parseByDays(tms8);
        this.times8Days = res8[1];
        this.times8 = res8[0];
      }
    },
    parseByDays(del) {
      var hoursT = null;
      var resultArr = [];
      var tempMin = [];
      var bydays = [];
      var day='';
      var tmsFrom = del.replace(/ /g,'')
      var fromArray = tmsFrom.split(',');
      hoursT = null;
      resultArr = [];
      tempMin = [];
      //добавим дни недели
      bydays = [];

      for (var key in fromArray) {
        //содержимое элементов
        if (fromArray[key].indexOf('$')> -1) {

          if (day!='') {

            resultArr.push([hoursT,tempMin]);
            bydays.push([day,resultArr]);

            resultArr=[]
            hoursT = null
            tempMin = []
          }
          day = fromArray[key].replace('$', '');
          continue;
        }

        var time = fromArray[key].split(':');
        if (time[0] != hoursT) {
          if (hoursT != null) {
            resultArr.push([hoursT,tempMin]);
            tempMin = [];
            hoursT = time[0];
          } else {
            hoursT = time[0];
          }
        }
        tempMin.push(time[1]);
      }
      if (fromArray.length > 1) {
        resultArr.push([hoursT,tempMin]);
      }
      bydays.push([day,resultArr])
      return [resultArr,bydays];
    }
  }
}
</script>

<style scoped>
.way {
  position: absolute;
  top: 0;
  bottom: 0;

  left: 0;
  right: 0;
}
.infomass {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A4ABAF;
  margin-bottom: 20px;
  margin-top: 5px;
}
.info {
  padding: 0 41px 0 59px;
  margin-top: 15px;
}
.info .title {
  ont-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
}
.cRoute {
  margin: 8px 0;
}
.cRoute .changeRoute.active {
  color: #26A242;
  background: #EBFAEE;
  cursor: unset;
}
.cRoute .changeRoute {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #A4ABAF;
  padding: 4px 6px 5px;
  background: #FAFAFA;
  border-radius: 3px;
  transition: all ease-out .3s;
  margin-bottom: 10px;
  cursor: pointer;
}
.cRoute .changeRoute span {
  white-space: nowrap;
}
.toBack {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 20px;
  top: 9px;
  cursor: pointer;
}
.toBack:before {
  content: "";
  position: absolute;
  width: 11px;
  height: 2px;
  left: 14px;
  top: 16px;
  background: #A4ABAF;
  transform: matrix(0.71, -0.67, 0.75, 0.71, 0, 0);
  transition: all ease-out .3s;
}
.toBack:after {
  content: "";
  position: absolute;
  width: 11px;
  height: 2px;
  top: 22px;
  left: 14px;
  background: #A4ABAF;
  transform: matrix(0.71, 0.67, -0.75, 0.71, 0, 0);
  transition: all ease-out .3s;
}
.toBack:hover:after,.toBack:hover:before {
  background: #000;
}
.pathPlace {

  position: relative;
}
.showMore {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 8px 5px;
  color: #9CACBA;
  margin-left: -8px;
  background: #F2F4F5;
  border-radius: 3px;
}
.pathPlace .leftLine {
  position: absolute;
  width: 0px;
  border-left: 1px solid transparent;
  top: 30px;
  left: -15px;
  bottom: 25px;
}
.pathStop .stop {
  padding: 17px 0 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  position: relative;
  border-bottom: 1px solid #EEEEEE;
}
.pathStop .stop.hidden {
  display: none;
}
.pathStop .stop .leftPoint{
  position: absolute;
  width: 5px;
  height: 5px;
  border: 2px solid transparent;
  border-radius: 50%;
  left: -19px;
  top: calc(50% - 4px);
}
.pathStop .stop.start .leftPoint{
  top: 22px;
}
.pathStop .stop.end .leftPoint{
  top: 22px;
}
.pathStop .stop.start,.pathStop .stop.end {
  font-weight: 600;
}
.pathStop .stop.end {
  border-color: transparent;
}
.waysplace {
  display: flex;
  align-items: center;
  padding: 0 41px 40px 59px;
  background-color: #FAFAFA;
  align-items: baseline;
  height: calc(100% - 40px);
}
.routePlace {
  flex-grow: 1;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.stop:before {
  content: "";
  background: transparent;
  position: absolute;
  left: -5px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 5px;
  transition: all ease-out .3s;
}
.stop.clicked:before {
  background: #F2F4F5;
  z-index: 1;
}
.stop.clicked > span {
  position: relative;
  z-index: 1;
  color: #26A242;
}
.timesSpace {
  display: flex;
  flex-direction: column;

}

.timesSpace .hours {
  display: flex;
  flex-flow: row wrap;
}
.timesSpace .hours > span{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  min-width: 20px;
}
.timesSpace .hours .minutes:nth-of-type(7n){
  margin-left: 30px;
}
.timesSpace .hours > * {
  margin-right: 10px;
}
.timesSpace .hours > *:last-child {
  margin-right: 0px;
}
.timesSpace .hours .minutes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #738898;
  min-width: 20px;
  text-align: left;
}
.timesPlace {
  margin: 5px 0 10px;
  display: flex;
}
.timesPlace.compact {
  margin: 5px 0 -19px;
}
.timesPlace > div {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #377ABD;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: all ease-out .3s;
  margin-right: 10px;
}
.timesPlace > div:hover, .timesPlace > div.is-active {
  border-color: #377ABD;
}
.timesPlace > div:last-child {
  margin-right: 0;
}
/*
.fade-up-enter-active,
.fade-up-leave-active {
  transition: all 0.3s ease-in-out;
}
.fade-up-enter,
.fade-up-leave-to {
  height: 0;
  transform: translateY(30px);
  opacity: 0;
}*/
.kostyil {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.7);
}
.kostyil .pls {
  display: block;
  background-color: #fff;
  display: flex;
}
.timeLegend {
  display: flex;
}
.timeLegend >div{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  min-width: 20px;
  color: #738898;
}
.timeLegend >div:first-of-type{
  margin-right: 10px;
}
</style>
