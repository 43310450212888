<template>
  <div class="detail">
    <div class="workPlace">
      <div class="toBack" @click="$emit('CloseDetail',null)"></div>
      <div v-if="oneway!=null">
        <div class="waysplace">
          <div :class="['way']" v-for="(way,ind) in oneway.way" :key="ind" :style="{background:oneway.color[ind]}">
            {{way}}
          </div>
        </div>
        <span class="title">
            {{oneway.path.length}} {{chisl(oneway.path.length,['остановка','остановки','остановок'])}}
          </span>
        <span class="blocktype">без пересадок</span>
        <div class="pathPlace">
          <div class="leftLine" :style="{backgroundColor: oneway.color[0]}"></div>
          <div class="pathStop" v-for="(stop,index) in oneway.path" :key="index">
            <div :class="['stop',(index==0)?'start':'',(index+1==oneway.path.length)?'end':'',(oneway.path.length>hideMin && index>hideStart && index<(oneway.path.length-hideEnd) && hideMore)?'hidden':'',(clicked==stop.name)?'clicked':'']">
              <div class="leftPoint" :style="{backgroundColor: oneway.color[0]}"></div>

              <span v-if="index!=hideStart || !hideMore" >{{stop.name}}</span>

              <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{oneway.path.length-hideStart-hideEnd}} {{chisl((oneway.path.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>

            </div>

          </div>
        </div>
      </div>

      <div v-if="oneseat!=null">
        <div class="waysplace">
          <div :class="['way']" v-for="(way,ind) in oneseat.first.way" :key="way" :style="{background:oneseat.first.color[ind]}">
            {{way}}
          </div>
          <div class="triangle-right"></div>
          <div :class="['way']" v-for="(way,ind) in oneseat.second.way" :key="way" :style="{background:oneseat.second.color[ind]}">
            {{way}}
          </div>
        </div>
        <span class="title">
            {{oneseat.first.path.length+oneseat.second.path.length-1}} {{chisl((oneseat.first.path.length+oneseat.second.path.length-1),['остановка','остановки','остановок'])}}
          </span>
        <span class="blocktype">одна пересадка</span>
        <div class="pathPlace">
          <div class="leftLine" :style="{borderColor: oneseat.first.color[0]}"></div>
          <div class="pathStop" v-for="(stop,index) in oneseat.first.path" :key="index">
            <div :class="['stop','first',(index==0)?'start':'',(index+1==oneseat.first.path.length)?'end':'',(oneseat.first.path.length>hideMin && index>hideStart && index<(oneseat.first.path.length-hideEnd) && hideMore_1)?'hidden':'',(clicked==stop.name)?'clicked':'']">
              <div class="leftPoint" :style="{backgroundColor: (index==0)?oneseat.first.color[0]:((index==hideStart && hideMore_1)?oneseat.first.color[0]:'#ffffff'),borderColor:(index==hideStart && hideMore_1)?'#FAFAFA':oneseat.first.color[0]}"></div>

              <span v-if="index!=hideStart || !hideMore_1 || (oneseat.first.path.length-hideStart-hideEnd==1)">{{stop.name}}</span>

              <span v-if="index==hideStart && hideMore_1 && (oneseat.first.path.length-hideStart-hideEnd!=1)" class="showMore" @click="hideMore_1=false">еще {{oneseat.first.path.length-hideStart-hideEnd}} {{chisl((oneseat.first.path.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>

            </div>

          </div>
        </div>

        <div class="pathPlace nh">
          <div class="leftLine" :style="{borderColor: '#9CACBA'}"></div>
          <div class="pathStop">
            <div :class="['stop']">
              <div class="leftPoint" :style="{backgroundColor: '#26A242',borderColor:'#FAFAFA'}"></div>
              <span class="showMore ">переход</span>
            </div>
          </div>
        </div>

        <div class="pathPlace">
          <div class="leftLine" :style="{borderColor: oneseat.second.color[0]}"></div>
          <div class="pathStop" v-for="(stop,index) in oneseat.second.path" :key="index">
            <div :class="['stop','second',(index==0)?'start':'',(index+1==oneseat.second.path.length)?'end':'',(oneseat.second.path.length>hideMin && index>hideStart && index<(oneseat.second.path.length-hideEnd) && hideMore_2)?'hidden':'',(clicked==stop.name)?'clicked':'']">
              <div class="leftPoint" :style="{backgroundColor: (index+1==oneseat.second.path.length)?oneseat.second.color[0]:((index==hideStart && hideMore_2)?oneseat.second.color[0]:'#ffffff'),borderColor:(index==hideStart && hideMore_2)?'#FAFAFA':oneseat.second.color[0]}"></div>

              <span v-if="index!=hideStart || !hideMore_2 || (oneseat.first.path.length-hideStart-hideEnd==1)" >{{stop.name}}</span>

              <span v-if="index==hideStart && hideMore_2 && (oneseat.second.path.length-hideStart-hideEnd!=1)" class="showMore" @click="hideMore_2=false">еще {{oneseat.second.path.length-hideStart-hideEnd}} {{chisl((oneseat.second.path.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>

            </div>

          </div>
        </div>
      </div>

      <div v-if="twoseat!=null">
        <div class="waysplace">
          <div :class="['way']" v-for="(way,ind) in twoseat.first.way" :key="way" :style="{background:twoseat.first.color[ind]}">
            {{way}}
          </div>
          <div class="triangle-right"></div>
          <div :class="['way']" v-for="(way,ind) in twoseat.second.way" :key="way" :style="{background:twoseat.second.color[ind]}">
            {{way}}
          </div>
          <div class="triangle-right"></div>
          <div :class="['way']" v-for="(way,ind) in twoseat.third.way" :key="way" :style="{background:twoseat.third.color[ind]}">
            {{way}}
          </div>
        </div>
        <span class="title">
            {{twoseat.first.path.length+twoseat.second.path.length+twoseat.third.path.length-2}} {{chisl((twoseat.first.path.length+twoseat.second.path.length+twoseat.third.path.length-2),['остановка','остановки','остановок'])}}
          </span>
        <span class="blocktype">две пересадки</span>
        <div class="pathPlace">
          <div class="leftLine" :style="{borderColor: twoseat.first.color[0]}"></div>
          <div class="pathStop" v-for="(stop,index) in twoseat.first.path" :key="index">
            <div :class="['stop','first',(index==0)?'start':'',(index+1==twoseat.first.path.length)?'end':'',(twoseat.first.path.length>hideMin && index>hideStart && index<(twoseat.first.path.length-hideEnd) && hideMore__1)?'hidden':'',(clicked==stop.name)?'clicked':'']">
              <div class="leftPoint" :style="{backgroundColor: (index==0)?twoseat.first.color[0]:((index==hideStart && hideMore__1)?twoseat.first.color[0]:'#ffffff'),borderColor:(index==hideStart && hideMore__1)?'#FAFAFA':twoseat.first.color[0]}"></div>

              <span v-if="index!=hideStart || !hideMore__1 || (twoseat.first.path.length-hideStart-hideEnd==1)" >{{stop.name}}</span>

              <span v-if="index==hideStart && hideMore__1 && (twoseat.first.path.length-hideStart-hideEnd!=1)" class="showMore" @click="hideMore__1=false">еще {{twoseat.first.path.length-hideStart-hideEnd}} {{chisl((twoseat.first.path.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>

            </div>

          </div>
        </div>

        <div class="pathPlace nh">
          <div class="leftLine" :style="{borderColor: '#9CACBA'}"></div>
          <div class="pathStop">
            <div :class="['stop']">
              <div class="leftPoint" :style="{backgroundColor: '#26A242',borderColor:'#FAFAFA'}"></div>
              <span class="showMore ">переход</span>
            </div>
          </div>
        </div>

        <div class="pathPlace">
          <div class="leftLine" :style="{borderColor: twoseat.second.color[0]}"></div>
          <div class="pathStop" v-for="(stop,index) in twoseat.second.path" :key="index">
            <div :class="['stop','second',(index==0)?'start':'',(index+1==twoseat.second.path.length)?'end':'',(twoseat.second.path.length>hideMin && index>hideStart && index<(twoseat.second.path.length-hideEnd) && hideMore__2)?'hidden':'',(clicked==stop.name)?'clicked':'']">
              <div class="leftPoint" :style="{backgroundColor: (index==hideStart && hideMore__2)?twoseat.second.color[0]:'#ffffff',borderColor:(index==hideStart && hideMore__2)?'#FAFAFA':twoseat.second.color[0]}"></div>

              <span v-if="index!=hideStart || !hideMore__2 || (twoseat.second.path.length-hideStart-hideEnd==1)" >{{stop.name}}</span>

              <span v-if="index==hideStart && hideMore__2 && (twoseat.second.path-hideStart-hideEnd!=1)" class="showMore" @click="hideMore__2=false">еще {{twoseat.second.path.length-hideStart-hideEnd}} {{chisl((twoseat.second.path.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>

            </div>
          </div>
        </div>

        <div class="pathPlace nh">
          <div class="leftLine" :style="{borderColor: '#9CACBA'}"></div>
          <div class="pathStop">
            <div :class="['stop']">
              <div class="leftPoint" :style="{backgroundColor: '#26A242',borderColor:'#FAFAFA'}"></div>
              <span class="showMore ">переход</span>
            </div>
          </div>
        </div>

        <div class="pathPlace">
          <div class="leftLine" :style="{borderColor: twoseat.third.color[0]}"></div>
          <div class="pathStop" v-for="(stop,index) in twoseat.third.path" :key="index">
            <div :class="['stop','second',(index==0)?'start':'',(index+1==twoseat.third.path.length)?'end':'',(twoseat.third.path.length>hideMin && index>hideStart && index<(twoseat.third.path.length-hideEnd) && hideMore__3)?'hidden':'',(clicked==stop.name)?'clicked':'']">
              <div class="leftPoint" :style="{backgroundColor: (index+1==twoseat.third.path.length)?twoseat.third.color[0]:((index==hideStart && hideMore__3)?twoseat.third.color[0]:'#ffffff'),borderColor:(index==hideStart && hideMore__3)?'#FAFAFA':twoseat.third.color[0]}"></div>

              <span v-if="index!=hideStart || !hideMore__3 || (twoseat.third.path.length-hideStart-hideEnd==1)" >{{stop.name}}</span>

              <span v-if="index==hideStart && hideMore__3 && (twoseat.third.path.length-hideStart-hideEnd!=1)" class="showMore" @click="hideMore__3=false">еще {{twoseat.third.path.length-hideStart-hideEnd}} {{chisl((twoseat.third.path.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>

            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'Detail',
  props: ['oneway','oneseat','twoseat','threeseat','clicked'],
  mounted() {
    this.initData()
  },
  components: {},
  data() {
    return {
      onewayInfo:null,
      oneseatInfo: null,
      twoseatInfo: null,
      threeseatInfo:null,

      hideMore:true,
      hideMore_1: true,
      hideMore_2: true,
      hideMore__1: true,
      hideMore__2: true,
      hideMore__3: true,
      hideMin: 4,
      hideStart: 1,
      hideEnd: 1,


    }
  },
  watch: {

    clicked: {
      handler(newValue, oldValue) {
        if(newValue !== oldValue) {
          let _this=this
          this.hideMore = false
          this.hideMore_1 = false
          this.hideMore_2 = false
          this.hideMore__1 = false
          this.hideMore__2 = false
          this.hideMore__3 = false
          setTimeout(function () {
            var el = _this.$el.getElementsByClassName("clicked")[0];
            el.scrollIntoView({block: 'center'});
          },50)


        }
      },
      deep: true //если нужно отслеживать изменение свойств
    }
  },
  methods: {
    initData() {

    },
    chisl(number, titles) {
      var cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
  }
}
</script>

<style scoped>
.detail {
  position: absolute;
  top: 0;
  bottom: 0;

}
.workPlace {
  padding: 20px 41px 30px 59px;
  position: relative;
  background: #FAFAFA;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  display: block;
  margin-top: 5px;
}
.blocktype {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #9CACBA;
  display: block;
  margin-bottom: 20px;
}

.way {
  display: inline-block;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  text-align: center;
  padding: 8px 0;
  width: 40px;
  position: relative;
  margin-bottom: 5px;
  margin-right: 3px;
}
.way.last {

}
.toBack {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 20px;
  top: 16px;
  cursor: pointer;
}
.toBack:before {
  content: "";
  position: absolute;
  width: 11px;
  height: 2px;
  left: 14px;
  top: 16px;
  background: #A4ABAF;
  transform: matrix(0.71, -0.67, 0.75, 0.71, 0, 0);
  transition: all ease-out .3s;
}
.toBack:after {
  content: "";
  position: absolute;
  width: 11px;
  height: 2px;
  top: 22px;
  left: 14px;
  background: #A4ABAF;
  transform: matrix(0.71, 0.67, -0.75, 0.71, 0, 0);
  transition: all ease-out .3s;
}
.toBack:hover:after,.toBack:hover:before {
  background: #000;
}
.pathPlace {
  position: relative;
  z-index: 2;
}
.pathPlace.nh {
  z-index: 1;
}
.showMore {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 8px 5px;
  color: #9CACBA;
  margin-left: -8px;
  background: #F2F4F5;
  border-radius: 3px;
  cursor: pointer;
}
.nh .showMore {
  cursor: unset;
}
.pathPlace .leftLine {
  position: absolute;
  width: 0px;
  border-left: 1px solid transparent;
  top: 30px;
  left: -15px;
  bottom: 25px;
}
.pathPlace.nh .leftLine {
  border-left: 1px dashed transparent;
  top: -50px;
  left: -15px;
  bottom: -50px;
}
.pathStop .stop {
  padding: 17px 0 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  position: relative;
  border-bottom: 1px solid #EEEEEE;
}
.pathStop .stop.hidden {
  display: none;
}
.pathStop .stop .leftPoint{
  position: absolute;
  width: 5px;
  height: 5px;
  border: 2px solid transparent;
  border-radius: 50%;
  left: -19px;
  top: calc(50% - 4px);
}
.pathStop .stop.start,.pathStop .stop.end {
  font-weight: 600;
}
.pathStop .stop.end {
  border-color: transparent;
}
.waysplace {
  display: flex;
  align-items: center;
}
.triangle-right {
  display: inline-block;
  position: relative;
  margin: 0 3px;
  width: 10px;
  height: 20px;
  margin-bottom: 5px;
}
.triangle-right:before {
  content: "";
  position: absolute;
  top: calc(50% - 7px);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #B4B4B4;
  display: inline-block;
}
.stop:before {
  content: "";
  background: transparent;
  position: absolute;
  left: -5px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 5px;
  transition: all ease-out .3s;
}
.stop.clicked:before {
  background: #F2F4F5;
  z-index: 1;
}
.stop.clicked > span {
  position: relative;
  z-index: 1;
  color: #26A242;
}
</style>
