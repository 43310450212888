<template>
  <div class="stop">
    <div class="workPlace">
      <div class="toBack" @click="$emit('bback',null)"></div>
      <div class="title" v-if="title!=null">{{title}}</div>

      <div class="addToPath">
        <div class="from" @click="$emit('fPoint',{id:Number(stopids),text:title})">поехать отсюда</div>
        <div class="to" @click="$emit('tPoint',{id:Number(stopids),text:title})">сюда</div>
      </div>
      <div class="behindStop" v-if="ways!=null">
        <span>Через эту остановку {{(ways.length>1)?'идут':'идёт'}}</span>
        <div class="wayy" v-for="(way,index) in ways" v-bind:key="index" @click="$emit('showWay',way.id)">
          {{way.name}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Stop',
  props: ['stopid'],

  mounted() {
    this.waysInit()
  },
  data() {
    return {
      title: null,
      ways: null,
      stopids:null,
    }
  },
  watch: {
    stopid: function() {
      this.waysInit()
    }
  },
  methods: {
    waysInit() {
      let _this=this
      this.axios.get(_this.$root.apiUrl+'stopinfo/'+ this.stopid, )
          .then(function (response) {
            _this.title=response.data.name
            _this.ways=response.data.ways
            _this.stopids=response.data.id
            console.warn(response.data)
          })
          .catch(function (error) {
            console.log(error)
          });
    },

  }
}
</script>

<style scoped>
.workPlace {
  padding: 0 41px 30px 59px;
  position: relative;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
  margin-top: 15px;
}
.addToPath {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.addToPath > div {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #26A242;
  padding: 3px 11px 6px;
  background: #EBFAEE;
  border-radius: 3px;
  cursor: pointer;
}
.behindStop {

  border-radius: 10px;
}
.behindStop span{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  display: block;
  margin-bottom: 12px;
}
.behindStop .wayy {
  display: inline-block;
  background: #F2F4F5;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  text-align: center;
  padding: 8px 0;
  width: 39px;
  position: relative;
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;

}
.toBack {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 20px;
  top: -8px;
  cursor: pointer;
}
.toBack:before {
  content: "";
  position: absolute;
  width: 11px;
  height: 2px;
  left: 14px;
  top: 16px;
  background: #A4ABAF;
  transform: matrix(0.71, -0.67, 0.75, 0.71, 0, 0);
  transition: all ease-out .3s;
}
.toBack:after {
  content: "";
  position: absolute;
  width: 11px;
  height: 2px;
  top: 22px;
  left: 14px;
  background: #A4ABAF;
  transform: matrix(0.71, 0.67, -0.75, 0.71, 0, 0);
  transition: all ease-out .3s;
}
.toBack:hover:after,.toBack:hover:before {
  background: #000;
}
</style>
