<template>
  <div class="stopway" v-if="stops!=null">
    <div v-if="stops.length>0">
      <div class="title" >через эту остановку идут</div>
      <div class="ways">
        <div class="names" v-for="(way) in stops" :key="way.id" @click="showWay(way.id)">
          {{way.name}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'StopWay',
  props: ['stopid'],

  mounted() {
    this.waysInit()
  },
  data() {
    return {
      stops: null,
    }
  },
  methods: {
    waysInit() {
      let _this=this
      this.axios.get(_this.$root.apiUrl+'stopway/'+ _this.stopid.id, )
          .then(function (response) {
            _this.stops=response.data
          })
          .catch(function (error) {
            console.log(error)
          });
    },
    showWay (id) {
      this.$emit('shWay',id)
    }
  }
}
</script>

<style scoped>
.workPlace {
  padding: 0 41px 30px 59px;
  position: relative;
}
.ways .names {
  display: inline-block;
  background: #F2F4F5;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  text-align: center;
  padding: 8px 0;
  width: 39px;
  position: relative;
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
  transition: all ease-out .3s;
}
.ways .names:hover {
  background: #DAE0E4;
}
.stopway .title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A4ABAF;
  margin-bottom: 5px;
}
</style>
